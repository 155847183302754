@import "~@dewo/app/styles/theme.less";

.tabs {
  flex: 1;

  :global {
    .ant-tabs-tabpane {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

@import "~@dewo/app/styles/theme.less";

.searchbar {
  background-color: @body-background-secondary;
  border-color: @border-color-split;
  margin-bottom: 16px;
}

.rowTextContainer {
  flex: 0 0 100%;

  @media (min-width: @screen-sm) {
    flex: 1;
  }
}
